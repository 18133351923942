import React, { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import { QUALIFYING, TOURNAMENT_TYPE_BY_ID } from "../../constants/TournamentTypeConstants";
import QualifyingRosterApi from "../../httpClients/QualifyingRosterApi";
import MainRosterApi from "../../httpClients/MainRosterApi";
import CoachingStaffSection from "./CoachingStaffSection";
import { withRouter } from "react-router-dom";
import WBCCollapse from "../elements/WBCCollapse";
import CreateProfileForm from "../profile/create-form/CreateProfileForm";
import CoachingStaffContext from "../../contexts/CoachingStaffContext";

const CoachingStaff = withRouter(({ history }) => {
  const { isBOC } = useContext(AuthContext).state;
  const { teamId: fedTeamId } = useContext(SelectedTeamContext).state.team;
  const { selectedWbcYear } = useContext(WBCYearsContext);
  const coachingStaffContext = useContext(CoachingStaffContext);
  const { staff } = coachingStaffContext.state;

  const [tournamentType, setTournamentType] = useState(null);
  const [tournamentSettings, setTournamentSettings] = useState(null);

  const loadStaff = useCallback(() => {
    if (!fedTeamId || !tournamentType) {
      return;
    }

    coachingStaffContext.dispatch({ type: "reset" });
    const api = tournamentType === QUALIFYING ? QualifyingRosterApi : MainRosterApi;
    api.getTeamStaff(fedTeamId).then(response => {
      coachingStaffContext.dispatch({ type: "setStaff", staff: response });
    });
  }, [fedTeamId, tournamentType]);

  useEffect(() => {
    loadStaff();
  }, [loadStaff]);

  useEffect(() => {
    if (selectedWbcYear?.generalRosterSettings?.tournamentTypeId) {
      const selectedYearTournamentType = TOURNAMENT_TYPE_BY_ID[selectedWbcYear.generalRosterSettings.tournamentTypeId];
      const selectedYearTournamentSettings =
        selectedYearTournamentType === QUALIFYING
          ? selectedWbcYear.qualifyingTournamentSettings
          : selectedWbcYear.mainTournamentSettings;
      setTournamentType(selectedYearTournamentType);
      setTournamentSettings(selectedYearTournamentSettings);
    }
  }, [selectedWbcYear]);

  return (
    <div className="px-3 py-2">
      <WBCCollapse title="Create a new staff member" startsOpen={false}>
        <CreateProfileForm />
      </WBCCollapse>
      <CoachingStaffSection
        staff={{
          type: "Coaches",
          min: tournamentSettings?.coaches?.coachesMin,
          max: tournamentSettings?.coaches?.coachesMax
        }}
        isBOC={isBOC}
        personnel={staff?.coaches || []}
      />
      <CoachingStaffSection
        staff={{
          type: "Managers",
          min: tournamentSettings?.managers?.managersMin,
          max: tournamentSettings?.managers?.managersMax
        }}
        isBOC={isBOC}
        personnel={staff?.managers || []}
      />
      <CoachingStaffSection
        staff={{
          type: "Trainers",
          min: tournamentSettings?.trainers?.trainersMin,
          max: tournamentSettings?.trainers?.trainersMax
        }}
        isBOC={isBOC}
        personnel={staff?.trainers || []}
      />
    </div>
  );
});

export default CoachingStaff;
