import React, { useCallback, useContext, useEffect } from "react";
import { MLBPlayersDashboardTab } from "../MLBPlayersDashboardStyles";
import ChronicConditionsTable from "./ChronicConditionsTable";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import ChronicConditionsContext from "../../../contexts/ChronicConditionsContext";
import ChronicConditionsHeader from "./ChronicConditionsHeader";
import ChronicConditionsApi from "../../../httpClients/ChronicConditionsApi";

const ChronicConditions = () => {
  const chronicConditionContext = useContext(ChronicConditionsContext);
  const { selectedWbcYear } = useContext(WBCYearsContext);
  const { setPlayerConditions, setInsuranceStatuses } = chronicConditionContext;
  const allStatus = { label: "All Insurance Statuses", value: null };

  const loadChronicConditionsByYear = useCallback(() => {
    if (selectedWbcYear?.year) {
      ChronicConditionsApi.getChronicConditionsByYear(selectedWbcYear.year).then(response => {
        setPlayerConditions(response?.length > 0 ? response.sort((a, b) => a.lastName.localeCompare(b.lastName)) : []);
      });
    }
    ChronicConditionsApi.getAllInsuranceStatuses().then(response => {
      if (response?.length > 0) {
        setInsuranceStatuses([
          allStatus,
          ...response.map(status => ({ label: status?.insuranceStatusName, value: status?.insuranceStatusId }))
        ]);
      }
    });
  }, [selectedWbcYear.year, setInsuranceStatuses, setPlayerConditions]);

  useEffect(() => {
    if (selectedWbcYear?.year) {
      loadChronicConditionsByYear();
    }
  }, [selectedWbcYear, loadChronicConditionsByYear]);

  return (
    <MLBPlayersDashboardTab>
      <ChronicConditionsHeader loadChronicConditionsByYearFn={loadChronicConditionsByYear} />
      <ChronicConditionsTable />
    </MLBPlayersDashboardTab>
  );
};

export default ChronicConditions;
