import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import WBCModal from "../elements/WBCModal";
import { ErrorModalBody, ErrorModalFooter } from "../error-modal/ErrorModal";
import DenyEligibilityModalBody from "../player-eligibility/DenyEligibilityModalBody";
import DenyEligibilityModalFooter from "../player-eligibility/DenyEligibilityModalFooter";
import { AreYouSureModalBodyContent, AreYouSureModalFooterContent } from "../elements/AreYouSureModal";
import {
  NoEbisMatchFoundModalBody,
  NoEbisMatchFoundModalFooter
} from "../profile/create-form/NoEbisMatchFoundModal/NoEbisMatchFoundModal";
import { UnsavedChangesModalBodyContent, UnsavedChangesModalFooterContent } from "../elements/UnsavedChangesModal";
import FinalizeRosterModalBody from "../roster/modals/FinalizeRosterModalBody";
import FinalizeRosterModalFooter from "../roster/modals/FinalizeRosterModalFooter";
import { WarningModalBody, WarningModalFooter } from "../warning-modal/WarningModal";
import CatcherModalBody from "../roster/modals/CatcherModalBody";
import CatcherModalFooter from "../roster/modals/CatcherModalFooter";
import PitcherModalBody from "../roster/modals/PitcherModalBody";
import PitcherModalFooter from "../roster/modals/PitcherModalFooter";
import InfielderModalBody from "../roster/modals/InfielderModalBody";
import InfielderModalFooter from "../roster/modals/InfielderModalFooter";
import OutfielderModalBody from "../roster/modals/OutfielderModalBody";
import OutfielderModalFooter from "../roster/modals/OutfielderModalFooter";
import AddNonGameDayModalBody from "../clubcomm/Modals/AddNonGameDayModalBody";
import AddNonGameDayModalFooter from "../clubcomm/Modals/AddNonGameDayModalFooter";
import AddPlayerModalBody from "../clubcomm/player/AddPlayerModalBody";
import AddPlayerModalFooter from "../clubcomm/player/AddPlayerModalFooter";
import RemovePlayerModalBody from "../clubcomm/player/RemovePlayerModalBody";
import RemovePlayerModalFooter from "../clubcomm/player/RemovePlayerModalFooter";
import PositionKeyModalBody from "../position-key-modal/PositionKeyModalBody";
import PositionKeyModalFooter from "../position-key-modal/PositionKeyModalFooter";
import HelpAndSupportModalBody from "../navigation/HelpAndSupportModalBody";
import HelpAndSupportModalFooter from "../navigation/HelpAndSupportModalFooter";
import { DiscardModalBody, DiscardModalFooter } from "../discard-modal/DiscardModal";
import DocumentsModalBody from "../documents/DocumentsModalBody";
import DocumentsModalFooter from "../documents/DocumentsModalFooter";
import DocumentsContextProvider from "../../providers/DocumentsContextProvider";
import {
  ConfirmCancelRequestModalContent,
  ConfirmCancelRequestModalFooterContent
} from "../elements/ConfirmCancelRequestModal";
import {
  ConfirmSubmitRequestModalContent,
  ConfirmSubmitRequestModalFooterContent
} from "../elements/ConfirmSubmitRequestModal";

const ModalContextProvider = ({ modalType, content, children }) => {
  switch (modalType) {
    case "documentsModal": {
      // when close is dispatched, content is set to undefined before modalType is updated
      if (content) {
        const { profileId, fedTeamId, labelTypeId } = content;
        return (
          <DocumentsContextProvider profileId={profileId} fedTeamId={fedTeamId} labelTypeId={labelTypeId}>
            {children}
          </DocumentsContextProvider>
        );
      } else {
        return children;
      }
    }
    default:
      return children;
  }
};

ModalContextProvider.displayName = "ModalContextProvider";

ModalContextProvider.propTypes = {
  modalType: PropTypes.string,
  content: PropTypes.shape({
    profileId: PropTypes.number.isRequired,
    fedTeamId: PropTypes.number.isRequired,
    labelTypeId: PropTypes.number.isRequired
  }),
  children: PropTypes.node
};

const GlobalModal = () => {
  // context(s)
  const globalModalContext = useContext(GlobalModalContext);

  // variables
  const {
    isOpen,
    headerText,
    modalType,
    content,
    overrideFn,
    closeCallback,
    saveChangesFn,
    saveChangesFnArg,
    discardChangesFn,
    discardChangesFnArg,
    discardChangesButtonText,
    addEventReportFn,
    onSaveSuccess,
    onConfirmUpdateFn,
    updateStatusButtonText,
    cancellationReason,
    profileId,
    onConfirmRequestFn,
    submitButtonText,
    submitProfile
  } = globalModalContext.state;

  // function(s)
  const toggle = () => globalModalContext.dispatch({ type: "closeModal" });

  const generateBodyContent = () => {
    switch (modalType) {
      case "errorModal":
        return <ErrorModalBody errors={content} />;
      case "warningModal":
        return <WarningModalBody text={content} />;
      case "denyEligibilityModal":
        return <DenyEligibilityModalBody />;
      case "areYouSureModal":
        return <AreYouSureModalBodyContent />;
      case "noEbisMatchModal":
        return <NoEbisMatchFoundModalBody ebisId={content} />;
      case "unsavedChangesModal":
        return <UnsavedChangesModalBodyContent />;
      case "finalizeRosterModal":
        return <FinalizeRosterModalBody />;
      case "catcherModal":
        return <CatcherModalBody />;
      case "pitcherModal":
        return <PitcherModalBody />;
      case "infielderModal":
        return <InfielderModalBody />;
      case "outfielderModal":
        return <OutfielderModalBody />;
      case "addNonGameDayModal":
        return <AddNonGameDayModalBody />;
      case "addPlayerModal":
        return <AddPlayerModalBody />;
      case "removePlayerModal":
        return <RemovePlayerModalBody content={content} />;
      case "positionKeyModal":
        return <PositionKeyModalBody />;
      case "helpAndSupportModal":
        return <HelpAndSupportModalBody />;
      case "discardModal":
        return <DiscardModalBody />;
      case "documentsModal":
        return <DocumentsModalBody />;
      case "confirmCancelModal":
        return <ConfirmCancelRequestModalContent content={content} />;
      case "confirmSubmitModal":
        return <ConfirmSubmitRequestModalContent content={content} submitProfile={submitProfile} />;
      default:
        return null;
    }
  };

  const generateFooterContent = () => {
    switch (modalType) {
      case "errorModal":
        return <ErrorModalFooter overrideFn={overrideFn} />;
      case "warningModal":
        return <WarningModalFooter saveFn={overrideFn} />;
      case "denyEligibilityModal":
        return <DenyEligibilityModalFooter onClose={closeCallback} />;
      case "areYouSureModal":
        return (
          <AreYouSureModalFooterContent
            onDiscardChanges={discardChangesFn}
            discardChangesFnArg={discardChangesFnArg}
            discardChangesButtonText={discardChangesButtonText}
          />
        );
      case "noEbisMatchModal":
        return <NoEbisMatchFoundModalFooter />;
      case "unsavedChangesModal":
        return (
          <UnsavedChangesModalFooterContent
            onSave={saveChangesFn}
            onSaveArg={saveChangesFnArg}
            onCancel={discardChangesFn}
            onCancelArg={discardChangesFnArg}
            globalModal={true}
          />
        );
      case "finalizeRosterModal":
        return <FinalizeRosterModalFooter />;
      case "catcherModal":
        return <CatcherModalFooter />;
      case "pitcherModal":
        return <PitcherModalFooter />;
      case "infielderModal":
        return <InfielderModalFooter />;
      case "outfielderModal":
        return <OutfielderModalFooter />;
      case "addNonGameDayModal":
        return <AddNonGameDayModalFooter addEventReportFn={addEventReportFn} />;
      case "addPlayerModal":
        return <AddPlayerModalFooter />;
      case "removePlayerModal":
        return <RemovePlayerModalFooter content={content} />;
      case "positionKeyModal":
        return <PositionKeyModalFooter />;
      case "helpAndSupportModal":
        return <HelpAndSupportModalFooter />;
      case "discardModal":
        return <DiscardModalFooter onDiscard={discardChangesFn} />;
      case "documentsModal":
        return <DocumentsModalFooter onSaveSuccess={onSaveSuccess} />;
      case "confirmCancelModal":
        return (
          <ConfirmCancelRequestModalFooterContent
            onConfirmUpdateFn={onConfirmUpdateFn}
            discardChangesFnArg={discardChangesFnArg}
            updateStatusButtonText={updateStatusButtonText}
            cancellationReason={cancellationReason}
            profileId={profileId}
          />
        );
      case "confirmSubmitModal":
        return (
          <ConfirmSubmitRequestModalFooterContent
            onConfirmRequestFn={onConfirmRequestFn}
            discardChangesFnArg={discardChangesFnArg}
            updateStatusButtonText={updateStatusButtonText}
            cancellationReason={cancellationReason}
          />
        );
      default:
        return null;
    }
  };

  // effects
  useEffect(() => {
    const elem = document.getElementById("modal-backdrop");
    if (isOpen && elem) elem.style.zIndex = 1053;
  }, [isOpen]);

  // content
  const bodyContent = generateBodyContent();
  const footerContent = generateFooterContent();

  return (
    <ModalContextProvider modalType={modalType} content={content}>
      <WBCModal isOpen={isOpen} headerText={headerText} toggleModal={toggle} onClose={closeCallback}>
        {bodyContent}
        {footerContent}
      </WBCModal>
    </ModalContextProvider>
  );
};

export default GlobalModal;
