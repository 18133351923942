import React, { useContext } from "react";
import PropTypes from "prop-types";
import WBCModal from "../../../elements/WBCModal";
import PersonMatchModalBody from "./PersonMatchModalBody";
import PersonMatchModalFooter from "./PersonMatchModalFooter";
import SelectedTeamContext from "../../../../contexts/SelectedTeamContext";

/**
 * Condense matches with the same match profile Id.
 * The array should always be small, so we can do other
 * logic over the list later
 */
const condenseMatches = (searchCriteria, matches, currentTeamId) => {
  const matchMap = {};
  const perfectMatchMap = {};
  matches.forEach(oldMatch => {
    const { matchProfileId, ebisId, birthCountry, fullName, birthDate, team, profileId } = oldMatch;
    const perfectMatch =
      !searchCriteria?.ebisId &&
      fullName?.toLowerCase() === searchCriteria?.personFullName?.toLowerCase() &&
      birthCountry?.countryName === searchCriteria?.birthCountry?.label &&
      birthDate === searchCriteria?.birthDate;
    const key = matchProfileId || ebisId;
    const match = perfectMatchMap[key] || matchMap[key] || { federations: [], isOnOwnFedTeam: false };
    match.ebisId = ebisId;
    match.birthCountryName = birthCountry.countryName;
    match.personFullName = fullName;
    match.birthDate = birthDate;
    match.profileId = match.profileId || matchProfileId || undefined;

    // check for federations and if on the current team
    if (team && team.fedTeamId) {
      match.isOnOwnFedTeam = match.isOnOwnFedTeam || team.fedTeamId === currentTeamId;
      if (team.fedTeamId === currentTeamId) {
        match.profileId = profileId;
      }
      match.federations.push(team.country.countryCode);
    }

    // add to map
    if (perfectMatch) {
      perfectMatchMap[key] = match;
    } else {
      matchMap[key] = match;
    }
  });

  return Object.keys(perfectMatchMap)
    .map(k => perfectMatchMap[k])
    .concat(Object.keys(matchMap).map(k => matchMap[k]));
};

const PersonMatchModal = ({ isOpen, setIsOpen, matchData, createPersonFn, searchByEbisId, personType }) => {
  // context(s)
  const selectedTeamContext = useContext(SelectedTeamContext);

  // variables
  let indexOfOwnFedTeam = -1;
  const { teamId } = selectedTeamContext.state.team || {};
  const searchCriteria = matchData[0];
  const matches = condenseMatches(searchCriteria, matchData.slice(1), teamId);
  const matchMetaData = {
    hasEbisId: false,
    hasFederations: false,
    hasProfileId: false
  };

  // calculate matchData metaData (i.e. contains ebisID, federations, etc.)
  matches.forEach((match, i) => {
    if (match.isOnOwnFedTeam) indexOfOwnFedTeam = i;
    if (match.ebisId) matchMetaData.hasEbisId = true;
    if (match.profileId) matchMetaData.hasProfileId = true;
    if (match.federations.length) matchMetaData.hasFederations = true;
  });

  // move own match to 1st item
  if (indexOfOwnFedTeam > 0) {
    const match = matches[indexOfOwnFedTeam];
    matches.splice(indexOfOwnFedTeam, 1);
    matches.unshift(match);
  }

  // add the search criteria to the beginning of the matches
  matches.unshift(searchCriteria);
  const personTypeLabel = personType === "player" ? "player" : "coaching staff member";
  const headerText = `The info entered matches ${
    matchMetaData.hasProfileId ? `existing ${personTypeLabel}(s)` : `an EBIS ${personTypeLabel}`
  }`;

  return (
    <WBCModal isOpen={isOpen} toggleModal={setIsOpen} headerText={headerText}>
      <PersonMatchModalBody
        matchData={matches}
        matchMetaData={matchMetaData}
        toggleModal={setIsOpen}
        personType={personType}
      />
      <PersonMatchModalFooter
        matchData={matches}
        setIsOpen={setIsOpen}
        createPlayerFn={createPersonFn}
        searchByEbisId={searchByEbisId}
        personType={personType}
      />
    </WBCModal>
  );
};

PersonMatchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  matchData: PropTypes.array.isRequired,
  createPersonFn: PropTypes.func.isRequired,
  searchByEbisId: PropTypes.bool,
  personType: PropTypes.string.isRequired
};

export default PersonMatchModal;
