import React, { useReducer, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ChronicConditionsContext from "../contexts/ChronicConditionsContext";

const DEFAULT_INSURANCE_STATUS_FILTER = { label: "All Insurance Statuses", value: null };

const INITIAL_STATE = {
  playerConditions: [],
  insuranceStatusFilter: DEFAULT_INSURANCE_STATUS_FILTER,
  insuranceStatuses: [],
  nameSearchFilter: null,
  sortFilters: {
    col: "lastName",
    dir: "ASC"
  }
};

const ChronicConditionsContextProvider = ({ children }) => {
  const [playerConditions, setPlayerConditions] = useState(INITIAL_STATE.playerConditions);
  const [insuranceStatusFilter, setInsuranceStatusFilter] = useState(INITIAL_STATE.insuranceStatusFilter);
  const [insuranceStatuses, setInsuranceStatuses] = useState(INITIAL_STATE.insuranceStatuses);
  const [nameSearchFilter, setNameSearchFilter] = useState(INITIAL_STATE.nameSearchFilter);
  const [sortFilters, setSortFilters] = useState(INITIAL_STATE.sortFilters);

  const filteredPlayerConditions = useMemo(() => {
    return playerConditions.filter(
      player =>
        (!nameSearchFilter ||
          player?.firstName?.toLowerCase().includes(nameSearchFilter?.toLowerCase()) ||
          player?.lastName?.toLowerCase().includes(nameSearchFilter?.toLowerCase())) &&
        (insuranceStatusFilter.value === null || player.insuranceStatus === insuranceStatusFilter.label)
    );
  }, [playerConditions, insuranceStatusFilter, nameSearchFilter]);

  const reducer = (state, action) => {
    switch (action.type) {
      case "sortPlayers":
        setPlayerConditions(action.playerConditions);
        return {
          ...state,
          sortFilters: {
            ...state.sortFilters,
            col: action.col,
            dir: action.dir
          },
          playerConditions: [...action.playerConditions]
        };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <ChronicConditionsContext.Provider
      value={{
        state,
        dispatch,
        sortFilters,
        playerConditions,
        setPlayerConditions,
        insuranceStatusFilter,
        setInsuranceStatusFilter,
        insuranceStatuses,
        setInsuranceStatuses,
        nameSearchFilter,
        setNameSearchFilter,
        filteredPlayerConditions
      }}
    >
      {children}
    </ChronicConditionsContext.Provider>
  );
};

ChronicConditionsContextProvider.propTypes = {
  children: PropTypes.object
};

export default ChronicConditionsContextProvider;
